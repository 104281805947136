import React from 'react';

function ArtIdentify({t}) {
  return (
    <div className="upload-form-container">
      <h2>Authentify Your Art Piece</h2>
      <p>This is the Authentify Your Art Piece component.</p>
    </div>
  );
}

export default ArtIdentify;
